body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0B0547;
  box-sizing: border-box;
}

body::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
  top: 0;
  opacity: 0.8;
  background: url("../assets/risingSun.png");
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
