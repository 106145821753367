@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0B0547;
  box-sizing: border-box;
}

body::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
  top: 0;
  opacity: 0.8;
  background: url(/static/media/risingSun.ad5ce23e.png);
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  font-family: 'Montserrat', sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.addFormMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
}

.MuiTabs-indicator {
    border-radius: 30px 30px 0 0;
    height: 7px !important;
    background: linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%);
}

.tabNum {
    font-family: 'Montserrat' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    min-width: 33.3% !important;
    color: white !important;
    text-transform: capitalize !important;
}

.MuiTabs-flexContainer {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    color: white;
}
.uploadFile {
    margin: .5em 0 1em 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    border: 2px dashed rgba(255, 255, 255, 1);
    height: 50vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: #FFFFFF;
    opacity: 0.4;
}

.addDescription{
    margin: .5em 0 0 0;
    width: 100%;
    border: none;
    color:white;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    resize: none;
    padding: .5em;
}
.h3em {
    height: 3em;
}
/* .uploadFile {
    margin: .5em 0 1em 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    border: 2px dashed rgba(255, 255, 255, 1);
    height: 20vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: #FFFFFF;
    opacity: 0.4;
} */

/* .uploadFile {
    margin: .5em 0 1em 0;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    border: 2px dashed rgba(255, 255, 255, 1);
    height: 20vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    color: #FFFFFF;
    opacity: 0.4;
} */

.main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    padding: 5vh;
}

.presaleColumn {
    min-width: 20vw;
    /* width: 50vw; */
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    margin: 0 0.3em;
}
.statusText {
    margin: 0.6em 0;
    color: #fff;
    font-family: 'Montserrat';
}

.details {
    background: rgb(255, 255, 255, 0.1);
    padding: 1em;
}

.logoAndTitle {
    display: flex;
}

.logo {
    margin: 0 0.3em 0 0;
    max-width: 4em;
}

.title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.token {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 0;
    font-family: 'Montserrat';
    color: #FFFFFF;
    opacity: 0.5;
    font-weight: 600;
}

.party {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    font-family: 'Montserrat';
    color: white;
    font-weight: bold;
}

.description {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #fff;
    font-family: 'Montserrat';
    font-weight: 400;
}

.contractLink {
    color: white;
    font-family: 'Montserrat';
    margin: 0.5em;
}

.buttonBox {
    display: flex;
    margin: 0.7em 0;
    flex-wrap: wrap;
}

.button {
    background: linear-gradient(255.52deg, rgba(2, 148, 171, 0.4) -173.55%, rgba(2, 148, 171, 0.4) 71.9%, rgba(60, 89, 190, 0.28) 117.57%);
    margin: 0.5em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: 0px solid #000;
}

.button:not(:first-child) {
    margin-right: 0.5em;
}

.button:first-child {
    margin-left: 0;
    margin-right: 0.5em;
}

.socmedLink {
    margin: 0.7em;
}

.socmedLink:first-child {
    margin-left: 0;
}

.detailsTitle {
    font-family: 'Montserrat';
    font-weight: 600;
    display: inline-block;
    margin-left: 0.5em;
    margin-top: 4vh;
    background-image: linear-gradient(157deg, #D7DDE8, #757F9A);

    -webkit-background-clip: text;

            background-clip: text;
    color: transparent;
}

.detailsInfo {
    background: rgb(255, 255, 255, 0.1);
}

.inputGroup{
  margin: 0px 0px 20px 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
}
.inputField{
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
}
.inputField:focus {
  box-shadow: none !important;
}

.boxDetail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-family: 'Montserrat';
    word-spacing: 0.1em;
    margin: 0 .5em;
    color: #fff;
}

.boxDetailTitle {
    color: #84858f;
    font-size: 14px;
    margin: 1em 0;
}

.boxDetailContent {
    color: #fff;
    font-size: 14px;
    margin: 0px;
}

.presaleStartTimeGMT {
    color: rgba(255, 255, 255, 0.7);
    font-size: 10px;
    display: flex;
    justify-content: flex-end;
    margin: auto 0;
}

.hr {
    border: 0;
    margin: 0.3em;
}

.hr:not(:last-child) {
    border: 0;
    background: rgba(255, 255, 255, 0.3);
    height: 0.1em;
}

.tokenomicInfo {
    padding: 1em;
    background: rgb(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
}

.tokenomicImage {
    max-width: 55vw;
    max-height: 55vh;
}

.comingSoonButton {
    background: linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%);
    opacity: 0.5;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 12px;
    border: none;
    flex: 1 1;
}

.comingSoonText{
    font-weight: 600;
    font-size: 15px;
    font-family: 'Montserrat';
    margin-bottom: auto 0;
}
.btn{
    border-color: transparent;
}
.bg-button{
    background:linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%);
}
.countdownBox {
    background: rgba(2, 148, 171, 0.2);
    display: flex;
    flex: 1 1;
    flex-direction: column;
    color: white;
}

.countdownBoxEnded{
    background: rgba(255, 113, 113, 0.3);
    display: flex;
    flex: 1 1;
    flex-direction: column;
    color: white;
}

.presaleTitle {
    font-family: 'Roboto';
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    margin: 0px;
    color: white;
    width: 100%;
}

.daysAndTime {
    display: flex;
    justify-content: space-around;
    color: white;
}

.HMS {
    display: flex;
}

.time {
    display: flex;
    flex-direction: column;
    margin: 0 0.1vh;
}

.number {
    font-size: 2em;
    font-weight: bold;
    font-family: 'Montserrat';
    margin: 0;
    display: flex;
    justify-content: center;
}

.dateInfo {
    color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: flex-end;
    font-family: 'Montserrat';
    font-weight: 600;
    /* font-size: 5vh; */
}
.homeMain {
    display: flex;
    flex-direction: column;
    margin: 2vw 9vh;
    flex-wrap: wrap;
}

.addLaunchpad {
    display: flex;
    justify-content: flex-end;
    margin: 1em 2em;
}

.addLaunchpadButton {
    background: linear-gradient(255.52deg, #0294AB -173.55%, #0294AB 71.9%, rgba(60, 89, 190, 0.7) 117.57%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 20px;
    padding: 1.5vh;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: .8em;
    color: white;
}

.projectsBox {
    margin: 2vw 0;
    /* min-width: 90vw; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    /* justify-content: center; */
}

.individualBox {
    /* border: 1px solid blue; */
    background: rgba(255, 255, 255, 0.1);
}

.statuses {
    display: flex;
    justify-content: space-between;
}

.statusOnSale, .statusComingSoon, .statusEnded {
    border-radius: 100px;
    padding: 4px 8px;
}

.statusOnSale {
    background: rgba(20, 163, 139, 0.5);
}

.statusComingSoon {
    background: rgba(255, 251, 0, 0.5);
}

.statusEnded {
    background: rgba(255, 113, 113, 0.5);
}

.status2 {
    display: flex;
    min-width: 4.5vw;
    justify-content: space-between;
    align-items: center;
}

.statusText {
    color: white;
    margin: 0px;
    font-size: 14px;
    padding-left: 4px;
}

.logoAndTitleBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1em;
}

.logoBox {
    display: flex;
    justify-content: center;
    position: relative;
}

.titleBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleBox1 {
    margin: 2.5vh auto 0;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600;
    font-size: 18px;
    font-family: 'Montserrat';
}

.titleBox2 {
    margin: 1vh auto;
    color: white;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Montserrat';
}

.otherLogoBox {
    display: flex;
    justify-content: space-around;
    align-content: center;
    margin: 0 30%;
}

.progress-bar {
    border-radius: 50px;
}

div.progress {
    border-radius: 50px;
    background: rgba(20, 163, 139, 0.3);
}

.progressInfo {
    font-family: 'Montserrat';
    color: white;
    font-size: .8rem;
    margin: 0 1em;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
}

.progressPercentage, .currentProgress {
    margin: 4px 0 4px 0;
}

.currentProgress {
    display: flex;
    flex-direction: row;
    font-size: .8rem;
    justify-content: flex-end;
}

.launchpadInfo {
    display: flex;
    flex-direction: column;
}

.stackingInfoTitle {
    color: #fff;
}

.h7 {
    font-size: 0.8em;
}

.container {
    max-width: 1080px;
}

.w80 {
    width: 80px;
}

input[class="accordion"] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.row {
    display: flex;
}

.row .col {
    flex: 1 1;
}

.row .col:last-child {
    margin-left: 1em;
  }

/* Accordion styles */
.tabs {
    border-radius: 8px;
    overflow: hidden;
    /* box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5); */
    width: 100%;
}

.tab {
    width: 100%;
    color: white;
    overflow: hidden;
}

.tab-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    /* background: #2c3e50; */
    font-weight: bold;
    cursor: pointer;
    /* Icon */
}

.tab-label:hover {
    background: rgba(26, 37, 47, 0.3);
}

.tab-label::after {
    content: "❯";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.35s;
}

.tab-content {
    max-height: 0;
    padding: 0 1em;
    color: white;
    background: transparent;
    transition: all 0.35s;
}

.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
}

.tab-close:hover {
    background: #1a252f;
}

input:checked+.tab-label {
    background: linear-gradient(96.51deg, #FF7171 2.96%, rgba(222, 28, 109, 0.78) 55.12%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

input:checked+.tab-label::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    background: linear-gradient(96.51deg, #FF7171 2.96%, rgba(222, 28, 109, 0.78) 55.12%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 1.5rem;
}

input:checked~.tab-content {
    max-height: 100vh;
    padding: 1em;
}
